import { readingTime } from 'reading-time-estimator'
import type { StoryblokStory } from 'storyblok-generate-ts'

type AnyStory = StoryblokStory<{ [k: string]: any }>

export function useReadingTime() {
  function getTextFromStoryObj<TContent extends AnyStory>(obj: TContent): string {
    let text = ''

    text += obj.content?.intro || ''

    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null)
        text += getTextFromStoryObj(obj[key] as AnyStory)
      else if (key === 'text')
        text += obj[key]
    }
    return text
  }

  const getReadingTime = (content: string) => {
    return readingTime(content, undefined, 'de')
  }

  const computeReadingTime = (story: AnyStory) => {
    const textContent = getTextFromStoryObj(story)
    const readingStats = getReadingTime(textContent)

    return readingStats.text
  }

  return {
    getReadingTime,
    getTextFromStoryObj,
    computeReadingTime,
  }
}
