import type { CardPostTeaserProps } from '@sozialinfo/kompass'
import type { CompetenceStoryFull, PostStoryFull } from '@/types/Story'
import { useReadingTime } from '@/composables/readingTime'

const { computeReadingTime } = useReadingTime()

export function usePostData() {
  function formatDate(date: string): string {
    return new Intl.DateTimeFormat('de-CH', {
      month: 'long',
      year: 'numeric',
    }).format(new Date(date))
  }

  function transformCompetenceToTagProps(competence: CompetenceStoryFull | string): CardPostTeaserProps['tags'][0] {
    if (typeof competence === 'string')
      return null

    return {
      name: competence.name,
      href: `/${competence.full_slug}`,
    }
  }

  function formatTeaserImage(imageSrc: string): string {
    return `${imageSrc}/m/920x0/smart`
  }

  function transformStoryToPostProps(story: PostStoryFull): CardPostTeaserProps {
    if (!story)
      return null

    return {
      id: story.uuid,
      title: story.content.title,
      date: formatDate(story.first_published_at),
      href: `/${story.full_slug}`,
      bookmarked: false, // TODO: implement bookmarking
      readingTime: computeReadingTime(story),
      tags: story.content.competences.map(competence => transformCompetenceToTagProps(competence)).filter(Boolean),
      image: {
        src: formatTeaserImage(story.content.teaser_image?.filename || story.content.image?.filename),
        alt: formatTeaserImage(story.content.teaser_image?.filename || story.content.image.alt),
      },
    }
  }

  return {
    transformStoryToPostProps,
    formatDate,
  }
}
