<script setup lang="ts">
import { onMounted, ref } from 'vue'
import StoryblokClient from 'storyblok-js-client'
import { Button, CardGrid, CardPostTeaser } from '@sozialinfo/kompass'
import { usePostData } from '@/composables/postData'
import type { PostStoryFull } from '@/types/Story'

const { token, limit } = defineProps<{ token: string, limit: number }>()

const Storyblok = new StoryblokClient({
  accessToken: token,
})

// will be handled in this component
if (history.scrollRestoration)
  history.scrollRestoration = 'manual'

const page = ref(1)
const totalItems = ref([])

const storedItems = sessionStorage.getItem('totalItems')
const storedPage = sessionStorage.getItem('page')

if (storedItems)
  totalItems.value = JSON.parse(storedItems)

if (storedPage)
  page.value = JSON.parse(storedPage)

const { transformStoryToPostProps } = usePostData()

async function loadMore() {
  page.value++

  const res = await Storyblok.get('cdn/stories', {
    token,
    version: 'published',
    starts_with: 'wissen',
    is_startpage: false,
    sort_by: 'first_published_at:desc',
    per_page: limit,
    page: page.value,
    resolve_relations: ['post.competences'],
  })
  const { stories } = res.data
  const lazyItems = stories.map((story: PostStoryFull) => transformStoryToPostProps(story))
  totalItems.value = [...totalItems.value, ...lazyItems]

  sessionStorage.setItem('totalItems', JSON.stringify(totalItems.value))
  sessionStorage.setItem('page', JSON.stringify(page.value))
}

function handleScroll() {
  sessionStorage.setItem('scrollPosition', String(window.scrollY))
}

onMounted(() => {
  window.scrollTo(0, Number(sessionStorage.getItem('scrollPosition') || 0))
})

document.addEventListener('scroll', handleScroll)
</script>

<template>
  <CardGrid class="mt-10 lg:mt-20">
    <CardPostTeaser
      v-for="item in totalItems"
      :key="item.id"
      v-bind="item"
    />
  </CardGrid>
  <div class="mx-auto mt-20 w-[200px]">
    <Button
      size="full"
      @click="loadMore"
    >
      Mehr laden
    </Button>
  </div>
</template>
